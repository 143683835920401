<template lang="pug">

div
  v-btn(fixed dark fab bottom right color="orange" @click="showModal = true")
    v-icon add
  v-text-field(clearable prepend-inner-icon="mdi-magnify" label="Search" v-model="search")
  v-list
    v-list-item(v-for="make in filteredMakes" :key="make['.key']")
      v-list-item-content
        v-list-item-title {{make.name}}
      v-list-item-action
        v-btn(text color="orange" @click="navToMake(make)") View Specs
  v-dialog(v-model="showModal" max-width="500px")
    v-card
      v-card-actions
        v-spacer
        v-btn(color="orange" text @click="showModal=false") Close
      v-card-text
        form
          v-text-field(v-model="newMake.name" label="Name" type="text")
      v-card-actions
        v-btn(color="primary" @click="addMake()") Add Make
</template>
<script>
import modal from '@/components/modal'
import { mapGetters } from 'vuex'

function initialUnit () {
  return {
    name: ''
  }
}

export default {
  name: 'app',
  data () {
    return {
      showModal: false,
      selected: '',
      search: '',
      newMake: initialUnit()
    }
  },
  methods: {
    navToMake (make) {
      this.$router.push({ path: `/makes/${make.id}` })
    },
    resetMake () {
      this.showModal = false
      this.newMake = initialUnit()
    },
    addMake () {
      this.search = this.newMake.name
      this.$store.dispatch('addMake', this.newMake)
      this.resetMake()
    }
  },
  computed: {
    ...mapGetters([
      'makes'
    ]),
    filteredMakes () {
      var that = this
      var data = this.makes
      return data.filter(make => {
        var searchRegex = new RegExp(that.search, 'i')
        return searchRegex.test(make.name)
      })
    }
  },
  components: {
    modal
  }
}
</script>
<style lang="stylus" scoped>

</style>
